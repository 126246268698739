<template>
	<div>
		<div v-if="loaded" class="tree-page opacity-page">
			<div class="header-tree">
				<div class="tree-actions">
					<div class="center-part public-title">
						<div class="text" @click="goToParent" v-if="$resize && $mq.above(600)" :style="`cursor: ${$route.params.slug ? 'pointer' : 'inital'}; text-decoration: ${$route.params.slug ? 'underline' : 'initial'}`">{{ companyData.company_name ? companyData.company_name : $t('companyData.private') }}</div>
					</div>

					<div class="right-part">
						<div class="btns-zoom">
							<div class="zoom-minus" @click="parseFloat(zoomPage).toFixed(1) > 0.50 ? zoomPage += -0.1 : zoomPage" v-bind:class="{disabled: zoomPage == 0.50}">-</div>
							<div class="current-zoom" @click="zoomPage = 1">{{ parseFloat(zoomPage * 100).toFixed(0) }}%</div>
							<div class="zoom-plus" @click="parseFloat(zoomPage).toFixed(1) == 1.50 ? zoomPage : zoomPage += 0.1" v-bind:class="{disabled: zoomPage == 1.50}">+</div>
						</div>
					</div>
				</div>
			</div>

			<div class="scrollable-chart" v-dragscroll>
				<div class="center-box" id="divToImage">
					<PublicTreeChart class="organigram-tbf" :json="Object.keys(companyData).length ? companyData : {}" :zoomPage="zoomPage" :key="treeChartKey" :filters="filtered" :style="'transform: scale(' + zoomPage + ');' " :promises="promisesObjectives" :densityType="densityType"/>
				</div>
			</div>
		</div>
		<organigram-loader v-else />
	</div>
</template>


<script type="text/javascript">
	import PublicTreeChart from './PublicTreeChart'
	import IconPlus from '../../Icons/Plus'
	import OrganigramLoader from '../../PagesLoaders/OrganigramLoader'

	export default {
		data() {
			return {
				loaded: false,
				filtered: {
					role: [],
					workstation: [],
					work_schedule: [],
					language: [],
					skill: []
				},
				companyData: {},
				totalChildrensJson: 0,
      			treeChartKey: 1,
      			zoomPage: 1,
      			promisesObjectives: { ready: false },
				densityType: 'minimal'
			};
		},
		components: {
			PublicTreeChart,
			IconPlus,
			OrganigramLoader,
		},
		watch:{
			$route (to, from){
				var jsonExtend = []
				localStorage.setItem('numberCount', 0)
				localStorage.setItem('numberCountExtend', 0)
				localStorage.setItem('jsonExtend', jsonExtend)

				if(to.name != from.name){
					this.getCompany()
				}
            }
        },
		async mounted() {
			var jsonExtend = []
			localStorage.setItem('numberCount', 0)
			localStorage.setItem('numberCountExtend', 0)
			localStorage.setItem('jsonExtend', jsonExtend)

			await this.getCompany()
		},
		methods: {
			async getCompany(){
				var link;
				
				if(this.$route.params.slug) {
					link = '/chart/' + this.$route.params.slug;
				} else {
					link = '/chart';
				}

				await axios.get(`/public/${this.$route.params.uuid}${link}`).then(({data}) => {
					this.companyData = data.data
					localStorage.setItem('numberCount', 0)
					this.countTotalItems(this.companyData)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).then(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			countTotalItems(jsonData){
				this.totalChildrensJson += 1
				if(jsonData.hasOwnProperty('children') && jsonData.children.length){
					jsonData.children.forEach(el => {
						this.countTotalItems(el)
					})
				}
				localStorage.setItem('numberCountExtend', this.totalChildrensJson)
			},
			goToParent(){
				if(this.$route.params.slug){
					this.$router.push({ name: 'public_company', params: {uuid: this.$route.params.uuid} })
				}
			}
		}
	};
</script>